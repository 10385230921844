<template>
  <div class="CountdownDiv">
    <h1>Preostalo vreme do događaja:</h1>
    <div class="countdownWrapper">
      <div class="timeWrap">
        <p>
          {{ Day }}
        </p>
        <h3>Dana</h3>
      </div>
      <!-- <div class="timeWrap p">
        <p>:</p>
      </div> -->

      <div class="timeWrap ">
        <p>
          {{ Hour }}
        </p>
        <h3>Sati</h3>
      </div>
      <!-- <div class="timeWrap p">
        <p>:</p>
      </div> -->
      <div class="timeWrap">
        <p>
          {{ Minute }}
        </p>
        <h3>Minuta</h3>
      </div>
      <!-- <div class="timeWrap p">
        <p>:</p>
      </div> -->
      <div class="timeWrap">
        <p>
          {{ Second }}
        </p>
        <h3>Sekunde</h3>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CountdownDiv",
  data() {
    return {
      Day: "",
      Hour: "",
      Minute: "",
      Second: "",
    };
  },
  methods: {
    countdown() {
      const countDate = new Date("June 28, 2022 19:00:00").getTime();
      const now = new Date().getTime();
      const gap = countDate - now;

      const seconds = 1000;
      const minute = seconds * 60;
      const hour = minute * 60;
      const day = hour * 24;

      this.Day = Math.floor(gap / day);
      this.Hour = Math.floor((gap % day) / hour);
      this.Minute = Math.floor((gap % hour) / minute);
      this.Second = Math.floor((gap % minute) / seconds);
    },
  },
  mounted: function () {
    this.countdown();
    window.setInterval(() => {
      this.countdown();
    }, 1000);
  },
};
</script>

<style scoped>
.CountdownDiv {
  margin: 40px auto;
}
.countdownWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.p{
    align-items: flex-start;
    align-content: flex-start;
    justify-content: start;
}
.timeWrap {
  margin: 20px;
}
.timeWrap h3 {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 15px;
}
.timeWrap p {
  font-family: "Inter";
  font-size: 30px;
  padding: 10px;
}
@media only screen and (max-width: 600px) {
.timeWrap p {
  font-family: "Inter";
  font-size: 25px;
  padding: 5px;
  font-weight: bolder;
}
.timeWrap {
  margin: 10px 5px;
}
.timeWrap h3 {
  text-transform: uppercase;
  font-weight: bolder;
  font-size: 10px;
}
h1{
    font-size: 15px;
}
}
</style>
