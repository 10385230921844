<template>
  <div id="app">
    <!-- <div class="hero">
      <img alt="Vue logo" src="./assets/kultura1.png" />
      <h1 class="event-title">KULTURA NIJE NA PRODAJU!</h1>
    </div> -->
    <CountdownDiv></CountdownDiv>
    <aplicationForm></aplicationForm>
    <BFooter></BFooter>
  </div>
</template>
<script>
/* eslint-disable */
import aplicationForm from "./components/aplicationForm.vue";
import BFooter from "./components/UI/BFooter.vue";
import CountdownDiv from "./components/Countdown.vue";

export default {
  name: "App",
  components: {
    aplicationForm,
    BFooter,
    CountdownDiv,
  },
  data() {
    return {};
  },
};
</script>

<style>
body {
  background: white;
}
* {
  margin: 0;
  font-family: "Roboto", sans-serif;
}
#app {
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
img {
  /* rotate: ; */
  /* width: 50px; */
}
.hero {
  background-image: url(./assets/h.jpg);
  background-position: center;
  background-size: cover;
  box-shadow: inset 0 0 0 2000px rgba(14, 14, 14, 0.615);
  height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
h1 {
}
.event-title {
  font-family: "Inter", sans-serif;
  font-size: 40px;
  font-weight: bolder;
  color: white;
  line-height: 40px;
}
</style>
