<template>
  <div class="form-container">
    <transition name="fade">
      <div v-if="submited" class="thank-you">
        <h2>Hvala što ste se prijavili</h2>
        <h2>Vidimo se!</h2>
      </div>
    </transition>
    <div class="form" v-if="!submited">
      <h2>Želite da gledam javnu raspravu!</h2>
      <p>Popunite prijavu ispod</p>
      <div class="form-attend">
        <md-field>
          <label>Ime</label>
          <md-input v-model="firstName"></md-input>
        </md-field>
        <md-field>
          <label for="lastName">Prezime</label>
          <md-input type="text" v-model="lastName" />
        </md-field>
        <md-field>
          <label for="email">Email adresa</label>
          <md-input type="email" v-model="email" />
        </md-field>
        <md-field>
          <label for="phoneNumber">Mobilni</label>
          <md-input type="number" v-model="phoneNumber" />
        </md-field>
        <md-button @click="submit()" class="md-raised md-primary"
          >Dolazim!</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
// import { response } from 'express';

export default {
  name: "aplicationForm",
  data() {
    return {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      submited: false,
    };
  },
  methods: {
    async submit() {
      if (
        this.firstName != "" &&
        this.lastName != "" &&
        this.email != "" &&
        this.phoneNumber != ""
      ) {
        const response = await fetch(
          "https://hooks.zapier.com/hooks/catch/9841582/bw4tuxh/?firstName=" +
            this.firstName +
            "&lastName=" +
            this.lastName +
            "&email=" +
            this.email +
            "&mobile=" +
            this.phoneNumber
        );
        if (response.status === 200) {
          this.submited = true;
          console.log(this.submited);
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.form {
  /* display: flex;
  flex-direction: column; */
  /* max-width: 400px; */
  background-color: #ededed36;
  padding: 50px;
  margin: 30px auto;
}
.form button {
  margin-top: 20px;
  padding: 5px;
}
.form-container {
  margin: 50px 0;
  min-height: 500px;
  display: flex;
  justify-content: center;
}
.thank-you {
  display: flex;
  flex-direction: column;
  /* justify-content: space-around; */
  align-items: center;
  align-content: center;
  justify-content: center;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.7s;
  transition-delay: 0.1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.form-attend{
  max-width: 75%;
  margin: 20px auto;
}

@media only screen and (max-width: 600px) {
 .form-attend{
  max-width: 100%;
 }
 .form{
  padding: 40px;
  width: 100%;
  margin: 5px;
 }
 h2{
  font-size: 17px;
  margin-bottom: 10px;
 }
}
</style>
