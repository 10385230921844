<template>
  <div class="footer">
    <h3>Sva prava zadržava Kreni-Promeni</h3>
  </div>
</template>

<script>
export default {
  name: 'BFooter',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
